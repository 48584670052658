import axios from "axios";
import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { Link, useHistory } from "react-router-dom";
import auctionService from "../../services/auction/auction.service";
import SearchBox from "../../common/SearchBox";

const CompletedAuction = () => {
  const token = localStorage.getItem("refreshToken");
  const history = useHistory();
  const user = jwtDecode(token);
  const source = axios.CancelToken.source();
  const [auctionData, setAuctionData] = useState([]);
  const [filterAuctionData, setFilterAuctionData] = useState([]);
  const [loading, setLoading] = useState(false);
  const headers = [
    { header: "Auction Id", dataKey: "c_auction_id" },
    { header: "Asset Count", dataKey: "asset_count" },
    { header: "Description", dataKey: "description" },
    { header: "Start Date", dataKey: "start_date" },
    { header: "End Date", dataKey: "end_date" },
    {
      header: "Financier",
      dataKey: "financier",
    },
    { header: "Segment", dataKey: "segment" },
    { header: "State", dataKey: "state" },
    { header: "ACR Locked", dataKey: "is_locked" },
    { header: "Viewable", dataKey: "viewable" },
  ];

  useEffect(() => {
    try {
      setLoading(true);
      const form_data = new FormData();
      form_data.append("key", "completed");
      auctionService.GetAuction(form_data, source).then((res) => {
        setLoading(false);
        const completed = res.completed;
        if (completed !== undefined) {
          setAuctionData(completed);
          setFilterAuctionData(completed);
        } else {
          setFilterAuctionData([]);
        }
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  const handeleRedirect = (c_auction_id) => {
    const newUrl = `/meratractor/auctions/modify?uid=${c_auction_id}`;
    history.push(newUrl);
  };

  const handleSwitch = (e, rowData) => {
    e.preventDefault();
    try {
      if (e.target.checked === true) {
        const form_data = new FormData();
        form_data.append("uid", rowData.c_auction_id);
        form_data.append("phone", user.phone);
        form_data.append("viewable", "true");
        auctionService.SwitchAuctionData(form_data, source).then((res) => {
          window.location.reload();
        });
      } else if (e.target.checked === false) {
        const form_data = new FormData();
        form_data.append("uid", rowData.c_auction_id);
        form_data.append("phone", user.phone);
        form_data.append("viewable", "false");
        auctionService.SwitchAuctionData(form_data, source).then((res) => {
          window.location.reload();
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSearch = (e) => {
    const { value } = e.target;

    const searchData = auctionData.filter((val) => {
      return String(val?.c_auction_id)
        .toLowerCase()
        .includes(value.toLowerCase());
    });

    if (value) {
      setFilterAuctionData(searchData);
    } else {
      setFilterAuctionData(auctionData);
    }
  };

  return (
    <>
      {loading ? (
        <div
          className="d-flex justify-content-center container"
          style={{ minHeight: "80vh", alignItems: "center" }}
        >
          <ReactLoading
            type={"bubbles"}
            color={"black"}
            height={100}
            width={100}
          />
        </div>
      ) : (
        <>
          <div className="container  table-responsive mt-2 text-center">
            <div className="mt-2">
              <div className="col-md-6 col-lg-4 col-12 ms-auto my-2 me-2">
                {/* <input
                  className="form-control"
                  type="text"
                  name="name"
                  placeholder="Search by Auction Id"
                  onChange={handleSearch}
                /> */}
                <SearchBox
                  placeholder="Search here..."
                  allData={auctionData}
                  setFilteredData={setFilterAuctionData}
                />
              </div>
            </div>
            <div
              className="table-responsive mt-2 text-center"
              style={{ minHeight: "5vh", maxHeight: "80vh" }}
            >
              <table className="table table-hover table-bordered">
                <thead className="thead-dark">
                  <tr
                    className="text-center"
                    style={{
                      fontSize: "small",
                      position: "sticky",
                      top: 0,
                      zIndex: 3,
                    }}
                  >
                    <th> Sr. No</th>
                    {headers.map((column) => (
                      <>
                        <th
                          className="col text-align-center"
                          key={column.header}
                        >
                          {column.header}
                        </th>
                        {column.header === "viewable" && (
                          <th
                            style={{
                              position: "sticky",
                              top: 0,
                              zIndex: 5,
                              display: "flex",
                              content: "center",
                            }}
                          >
                            Viewable
                          </th>
                        )}
                      </>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(filterAuctionData) &&
                  Object.entries(filterAuctionData).length === 0 ? (
                    <div className="text-danger mt-2 "></div>
                  ) : (
                    Object.entries(filterAuctionData).map(
                      ([key, rowData], rowIndex) => (
                        <tr
                          style={{
                            fontSize: "small",
                          }}
                        >
                          <td key={rowIndex}>{rowIndex + 1}</td>
                          <td
                            onClick={() =>
                              handeleRedirect(rowData.c_auction_id)
                            }
                            style={{ cursor: "pointer", fontWeight: "bold" }}
                            className="text-primary"
                          >
                            <Link
                              to={`/meratractor/auctions/modify?uid=${rowData.c_auction_id}`}
                            >
                              {rowData.c_auction_id}
                            </Link>
                          </td>
                          <td>{rowData.asset_count}</td>
                          <td>{rowData.description}</td>
                          <td>{rowData.start_date}</td>
                          <td>{rowData.end_date}</td>
                          <td>{rowData.financier}</td>
                          <td>{rowData.segment}</td>
                          <td>{rowData.state}</td>
                          <td>
                            {rowData?.is_locked === "True" ? (
                              <i class="bi bi-check-circle-fill text-success fs-6"></i>
                            ) : rowData?.is_locked === "False" ? (
                              <i class="bi bi-x-circle-fill text-danger fs-6"></i>
                            ) : (
                              "-"
                            )}
                          </td>
                          <td>
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id={`flexSwitchCheck${rowIndex}`}
                                defaultChecked={
                                  rowData.viewable &&
                                  rowData.viewable.toLowerCase() === "true"
                                }
                                onClick={(e) => handleSwitch(e, rowData)}
                              />
                            </div>
                          </td>
                        </tr>
                      )
                    )
                  )}
                </tbody>
              </table>
              {auctionData && auctionData.length === 0 && (
                <div className="text-danger text-center mt-2 ">No Data</div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CompletedAuction;
